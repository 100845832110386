<template>
  <the-new-header>
    <template v-slot:main-block>&nbsp;</template>
  </the-new-header>
  <div class="page-content add-application-page">
    <main class="full-width">

      <div class="add-app-block">
        <h1 class="page-title">Welcome to Asolytics - Your Partner for App Growth!</h1>
        <div class="page-subtitle">
          It’s great to have you on board. To begin, please add your first application.
          <strong>Let’s unlock your app’s potential together with Asolytics:</strong>
        </div>

        <div class="app-adding-filters">

          <div class="country-select country-select-col">
            <div class="column-title"><strong>1. </strong>Change country, if you need</div>
            <custom-select-search @add-app-search-handler="addAppSearchCountryHandler"
                                  @country-selected="countrySelectHandler"
                                  no-active-border
                                  :min-result-length="0"
                                  :min-input-length="0"
                                  :initial-data="countryInitial"
                                  @dropdown-closed="dropdownClosed"
                                  :search-results="searchCountryResults">
              <template v-slot:header>Select country</template>
            </custom-select-search>
          </div>

          <div class="platform-col">
            <div class="column-title"><strong>2. </strong> Select store</div>
            <div class="platform-switch">
              <div class="platform-item"
                   :class="{active: currentStore === 'APP_STORE'}"
                   @click="currentStoreChanged('APP_STORE')">
                <img src="@/assets/images/icons/app_store.png"
                     alt="Store icon"
                     class="store-icon"
                     width="20"
                     height="20">
              </div>
              <div class="platform-item"
                   @click="currentStoreChanged('GOOGLE_PLAY')"
                   :class="{active: currentStore === 'GOOGLE_PLAY'}">
                <img src="@/assets/images/icons/google_play.png"
                     alt="Store icon"
                     class="store-icon"
                     width="18"
                     height="20">
              </div>
            </div>
          </div>

          <div class="app-search-col">
            <div class="column-title"><strong>3. </strong> Type your app name, or paste store URL, app ID</div>
            <div class="app-search-form">
              <template v-if="currentStore">
                <basic-search-input placeholder="Search by Name, ID, URL"
                                    @search-input-changed="addAppSearchHandler"
                                    :search-results="searchResults"
                                    :hide-results-block="true"
                                    class="add-app-country-search"
                                    @search-outside-clicked="searchOutsideClicked"
                                    :input-disabled="!addedCountry?.code">
                  <template v-slot:search-icon>
                    <div class="preloader-wrap search-preloader"
                         v-if="searchIsLoading">
                      <preloader-table-small loader-size="20px"></preloader-table-small>
                    </div>
                    <svg-icon class="search-icon"
                              icon="search-solid"
                              @click="searchBtnClicked"
                              v-else/>
                  </template>

                  <template v-slot:alternative-results-block>
                    <div class="results-block-wrap"
                         v-if="showSearchResults && !searchIsLoading && currSearchInput !== ''">
                      <div class="results-block">
                        <div class="result-item add-item-block"
                             v-if="searchResults?.result?.length > 0"
                             v-for="result in searchResults.result"
                             @click="addAppHandler(result)">

                          <template v-if="result?.logo">
                            <div class="product-logo">
                              <app-image
                               :width="22"
                               :height="22"
                               alt="App image"
                               :lazy-src="result?.logo"
                              />
                            </div>
                          </template>
                          <template v-else>
                            <div class="empty-logo"></div>
                          </template>

                          <div class="product-name">{{ result?.title ?? '' }}</div>

                          <div class="add-app-button">
                            <svg-icon icon="plus"/>
                          </div>
                        </div>

                        <div v-else
                             class="no-results">
                          We couldn't find what you are looking for
                        </div>

                        <template
                         v-if="searchResults?.result?.length > 0 && JSON.stringify(secondSearchResults) === '{}'">
                          <div class="wait-next-request">
                            🕓 For more complete search results, wait a few more seconds...
                          </div>
                        </template>
                        <template v-if="searchResults?.result?.length > 0 && secondSearchResults?.result?.length > 0">
                          <div class="result-item add-item-block"
                               v-for="result in secondSearchResults.result"
                               @click="addAppHandler(result)">

                            <template v-if="result?.logo">
                              <div class="product-logo">
                                <app-image
                                 :width="22"
                                 :height="22"
                                 alt="App image"
                                 :lazy-src="result?.logo"
                                />
                              </div>
                            </template>
                            <template v-else>
                              <div class="empty-logo"></div>
                            </template>

                            <div class="product-name">{{ result?.title ?? '' }}</div>

                            <div class="add-app-button">
                              <svg-icon icon="plus"/>
                            </div>
                          </div>
                        </template>
                      </div>
                    </div>
                  </template>
                </basic-search-input>
              </template>
              <template v-else>
                <input type="text"
                       placeholder="Search by Name, ID, URL"
                       class="disabled-input"
                       title="Choose store!"
                       disabled>
              </template>
            </div>
          </div>

        </div>

      </div>
    </main>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import {httpRequest} from "@/api";
import TheNewHeader from "@/components/TheNewHeader/index.vue";
import PreloaderTableSmall from "@/components/UI/PreloaderTableSmall/index.vue";
import {cropImage, sanitizeString, setCookie, getCookie} from "@/utils/utils";
import BasicSearchInput from "@/components/UI/BasicSearchInput/index.vue";
import Accordion from "@/components/UI/Accordion/index.vue";
import CustomSelectWithSearch from "@/components/Forms/CustomSelectWithSearch/index.vue";

export default {
  name: 'Profile',
  components: {
    TheNewHeader,
    'preloader-table-small': PreloaderTableSmall,
    'basic-search-input': BasicSearchInput,
    'custom-accordion': Accordion,
    'custom-select-search': CustomSelectWithSearch,
  },
  data() {
    return {
      showAppDetails: false,
      searchResults: {},
      secondSearchResults: {},
      searchCountryResults: {},
      addedCountry: {},
      countryInitial: {
        code: 'US',
        name: 'United States'
      },
      fullCountriesList: [],
      currentStore: null,
      resultsCounter: 0,
      searchIsLoading: false,
      showSearchResults: false,
      currSearchInput: '',
      showAnimation: '',
    }
  },
  methods: {
    async addAppBtnClicked() {
      this.showAppDetails = !this.showAppDetails;

      if (this.showAppDetails === false) {
        this.currentStore = null;
        this.currSearchInput = null;
        this.searchResults = null;
        this.secondSearchResults = null;
        // this.searchIsLoading = false;
      } else {
        this.searchIsLoading = false;
        this.addedCountry = localStorage.getItem('addedCountry') ? JSON.parse(localStorage.getItem('addedCountry')) : {
          code: 'US',
          name: 'United States'
        };
        if (this.addedCountry) {
          this.countryInitial = {
            code: this.addedCountry?.code,
            name: this.addedCountry?.name,
          };
        }

        await this.addAppSearchCountryHandler();
      }
    },
    async addAppSearchHandler(value) {
      if (value?.length > 2) {
        this.currSearchInput = value;
        this.showSearchResults = false;
        this.searchIsLoading = true;
        const url = process.env.VUE_APP_API_URL
         + this.$ApiUrls.myApps.LIVE_SEARCH_APPLICATIONS
         + '?country_code=' + this.addedCountry?.code
         + '&query=' + value
         + '&store_key=' + this.currentStore
         + '&page=1&limit=10';
        const result = await httpRequest('GET', url);

        let searchResults;

        if (result?.result) {
          searchResults = {
            results: result?.result?.map(resultItem => {
              let logo = null;
              if (resultItem?.logo) {
                logo = resultItem?.logo;
              } else if (resultItem?.icon) {
                logo = resultItem?.icon;
              }
              logo = cropImage(logo, '22x22', resultItem?.store?.key);

              return {
                id: resultItem?.id ?? null,
                store_key: resultItem?.store?.key ?? null,
                logo,
                link: resultItem?.link ?? null,
                title: resultItem?.title ? sanitizeString(resultItem?.title) : null,
              }
            })
          };
        } else {
          searchResults = {
            results: []
          }
        }

        this.searchResults = {result: searchResults?.results?.filter(item => item !== null)};
        this.searchIsLoading = false;
        this.showSearchResults = true;

        setTimeout(async () => {
          await this.secondSearchRequest();
        }, 500);
      } else {
        this.searchResults = {};
      }
    },
    async secondSearchRequest() {
      if (!this.currSearchInput) {
        return;
      }

      const url = process.env.VUE_APP_API_URL
       + this.$ApiUrls.myApps.LIVE_SEARCH_APPLICATIONS
       + '?country_code=' + this.addedCountry?.code
       + '&query=' + this.currSearchInput
       + '&store_key=' + this.currentStore
       + '&page=2&limit=10';
      const result = await httpRequest('GET', url);

      let searchResults;

      if (result?.result) {
        searchResults = {
          results: result?.result?.map(resultItem => {
            let logo = null;
            if (resultItem?.logo) {
              logo = resultItem?.logo;
            } else if (resultItem?.icon) {
              logo = resultItem?.icon;
            }
            logo = cropImage(logo, '22x22', resultItem?.store?.key);

            return {
              id: resultItem?.id ?? null,
              store_key: resultItem?.store?.key ?? null,
              logo,
              link: resultItem?.link ?? null,
              title: resultItem?.title ? sanitizeString(resultItem?.title) : null,
            }
          })
        };
      } else {
        searchResults = {
          results: []
        }
      }

      this.secondSearchResults = {result: searchResults?.results?.filter(item => item !== null)};
    },
    async searchBtnClicked() {
      if (!this.currSearchInput) {
        this.searchIsLoading = false;
        return;
      }

      this.searchIsLoading = true;
      this.showSearchResults = false;

      const url = process.env.VUE_APP_API_URL
       + this.$ApiUrls.myApps.LIVE_SEARCH_APPLICATIONS
       + '?country_code=' + this.addedCountry?.code
       + '&query=' + this.currSearchInput
       + '&store_key=' + this.currentStore
       + '&page=1&limit=10';
      const result = await httpRequest('GET', url);

      let searchResults;

      if (result?.result) {
        searchResults = {
          results: result?.result?.map(resultItem => {
            let logo = null;
            if (resultItem?.logo) {
              logo = resultItem?.logo;
            } else if (resultItem?.icon) {
              logo = resultItem?.icon;
            }
            logo = cropImage(logo, '22x22', resultItem?.store?.key);

            return {
              id: resultItem?.id ?? null,
              store_key: resultItem?.store?.key ?? null,
              logo,
              link: resultItem?.link ?? null,
              title: resultItem?.title ? sanitizeString(resultItem?.title) : null,
            }
          })
        };
      } else {
        searchResults = {
          results: []
        }
      }

      this.searchResults = {result: searchResults?.results?.filter(item => item !== null)};

      this.searchIsLoading = false;
      this.showSearchResults = true;
      setTimeout(async () => {
        await this.secondSearchRequest();
      }, 500);
    },
    async addAppSearchCountryHandler(value) {
      const queryString = value?.length >= 0 ? '?query=' + value : null;
      if (queryString) {
        const result = await httpRequest('GET', process.env.VUE_APP_API_URL + this.$ApiUrls.myApps.SEARCH_LOCALES + queryString);
        const filteredCountries = result?.countries?.filter(country => {
          return country?.name?.toLowerCase()?.includes(value?.toLowerCase());
        });

        this.searchCountryResults = {result: filteredCountries?.filter(item => item?.code !== 'WORLDWIDE')};
      } else {
        const result = await httpRequest('GET', process.env.VUE_APP_API_URL + this.$ApiUrls.myApps.SEARCH_LOCALES);
        this.searchCountryResults = {result: result?.countries?.filter(item => item?.code !== 'WORLDWIDE')};
      }

      if (this.fullCountriesList?.length === 0) {
        this.fullCountriesList = await httpRequest('GET', process.env.VUE_APP_API_URL + this.$ApiUrls.myApps.SEARCH_LOCALES_WITH_ID);
      }
    },
    async countrySelectHandler(value) {
      this.addedCountry = value;

      await this.addAppSearchCountryHandler('');
    },
    async addAppHandler(value) {
      localStorage.setItem('addedCountry', JSON.stringify(this.addedCountry));
      await this.addModalAppClickHandler({
        application_id: value?.id,
        country_code: this.addedCountry?.code,
        fullCountriesList: this.fullCountriesList
      });

      this.$router.push({name: 'CompetitorsFinder'});
    },
    async dropdownClosed() {
      await this.addAppSearchCountryHandler('');
    },
    async outside() {
      this.showSearchResults = false;
      await this.addAppSearchCountryHandler('');
    },
    currentStoreChanged(store) {
      this.currentStore = store;
      if (this.currSearchInput !== '') {
        this.searchBtnClicked();

        if (this.currSearchInput === null) {
          this.currSearchInput = '';
        }
      }
    },
    async setCurrentLatestCountry(currAppId, countryId) {
      const currentAppId = currAppId ?? getCookie('currentAppId');
      const url = process.env.VUE_APP_API_URL + 'api/profiles/applications/' + currentAppId + '/' + countryId + '/used';
      await httpRequest('POST', url);
    },
    async addModalAppClickHandler(app) {
      let formData = new FormData();
      formData.append('application_id', app.application_id);
      formData.append('country_code', app.country_code);
      const result = await httpRequest('POST', process.env.VUE_APP_API_URL + this.$ApiUrls.myApps.MY_APPLICATIONS, formData);

      if (result?.status === 403) {
        this.closeModal('showAddAppModal');
        this.$store.dispatch('UPDATE_USER_LIMITS');
        return;
      }

      const fullCountriesList = app?.fullCountriesList ?? [];
      const currCountryArr = fullCountriesList.filter(country => country.code === app.country_code);
      const currAppArray = result?.applications?.filter(application => application.id === app.application_id);
      const currApp = currAppArray[0];
      const currCountryById = currCountryArr.filter(country => country?.store_id === currApp?.store_id);
      const currCountryByIdApp = currCountryById[0];

      if (currCountryByIdApp?.id) {
        await this.setCurrentLatestCountry(currApp.id, currCountryByIdApp.id);
      }

      if (getCookie('currentAppId') && getCookie('currentAppId') !== 'undefined') {
        this.closeModal('showAddAppModal');
        this.$store.dispatch('UPDATE_USER_LIMITS');
      } else {
        await this.setLatestCountry(app.application_id);
        this.$store.dispatch('SET_CURRENT_APP_RELOADED', true);
        this.$store.dispatch('SET_CURRENT_APP_ID', currApp.id);
        this.$store.dispatch('SET_CURRENT_APP', currApp);
        setCookie('currentAppId', currApp.id, null, 7);
        localStorage.setItem('doNotUpdatePage', 'true');
      }
    },
    async setLatestCountry(appId) {
      const currentAppId = appId ?? getCookie('currentAppId');

      if (!currentAppId || currentAppId === 'undefined') {
        return;
      }

      const result = await httpRequest('GET', process.env.VUE_APP_API_URL + 'api/profiles/applications/' + currentAppId + '/countries/latest');

      let country;
      if (result?.country) {
        country = {...result?.country};
      } else {
        country = {
          code: "US",
          id: 103,
          name: "United States",
        };
      }

      this.$store.dispatch('SET_CURRENT_COUNTRY', country);
    },
    searchOutsideClicked() {
      this.searchIsLoading = false;
      this.showSearchResults = false;
      this.searchResults = [];
    },
  },
  mounted() {
    if (getCookie('currentAppId')) {
      this.$router.push('/');
    }

    this.currentStore = null;
    this.currSearchInput = null;
    this.searchResults = null;
    this.secondSearchResults = null;

    this.addAppBtnClicked();
  },
  computed: {
    ...mapGetters([
      'callToAddAppCounter',
    ]),
  },
  watch: {
    callToAddAppCounter(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.showAnimation = true;

        setTimeout(() => {
          this.showAnimation = false;
        }, 500);
      }
    }
  }
}
</script>

<style lang="scss" src="./styles.scss"></style>